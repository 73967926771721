<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs v-if="userData" pills>
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Profile</span>
        </template>
        <user-edit-tab-account
          :user-data="userData"
          :skills-data="skillsData"
          :method="onSubmit"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Details</span>
        </template>
        <user-edit-tab-information
          :user-data="userData"
          :method="onSubmit"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Social 
      <b-tab>
        <template #title>
          <feather-icon icon="Share2Icon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Resume</span>
        </template>
        <user-edit-tab-social
          :user-data="userData"
          :pdf-data="pdfData"
          class="mt-2 pt-75"
        />
      </b-tab>-->
        <b-tab>
        <template #title>
          <feather-icon icon="MailIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Comments</span>
        </template>
       <b-row>
        <b-col
          cols="12"
          xl="7"
          lg="7"
          md="7"
        >
       <user-edit-tab-social
          :user-data="userData"
          :pdf-data="pdfData"
          class="mt-2 pt-75"
        />
        </b-col>
        <b-col
          cols="12"
          xl="5"
          lg="5"
          md="5"
        >
           <user-view-user-timeline-card :user-data="userData" />
        </b-col>
      </b-row>
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink,BRow,
    BCol } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import userStoreModule from "../userStoreModule";
import UserEditTabAccount from "./UserEditTabAccount.vue";
import UserEditTabInformation from "./UserEditTabInformation.vue";
import UserEditTabSocial from "./UserEditTabSocial.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { Vue } from 'vue-property-decorator';
import UserViewUserTimelineCard from "../users-view/UserViewUserTimelineCard.vue";

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    ToastificationContent,
    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabSocial,
    UserViewUserTimelineCard,
  },

  setup() {
    const userData = ref(store.state.candidate?store.state.candidate.candidate:null );
    const pdfData = ref(null);
    const skillsData = ref([]);

    const USER_APP_STORE_MODULE_NAME = "candidate";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch("candidate/fetchUser", { id: router.currentRoute.params.id })
      .then((response) => {
       // (userData.value = response.data.data),
       (userData.value = store.state.candidate.candidate)
          //response.data.data.candidateAddresses[0]? userData.value={...userData.value,...response.data.data.candidateAddresses[0],id:userData.value.id}:"",
          // store
          //   .dispatch("candidate/fetchPdf", {
          //     url: response.data.data.resumeFileUrl,
          //   })
          //   .then((response) => {
          //     const blob = new Blob([response.data]);
          //     const objectUrl = URL.createObjectURL(blob);
          //     pdfData.value = objectUrl;
          //   })
          //   .catch((error) => {
          //     for (const [key, value] of Object.entries(error.response.data.errors)) {
          //     Vue.$toast({
          //       component: ToastificationContent,
          //       props: {
          //         title: "error",
          //         icon: "AlertTriangleIcon",
          //         variant: "danger",
          //         text:value,
          //       },
          //     });
          //     }
          //     if (error.response.status === 404) {
          //       pdfData.value = undefined;
          //     }
          //   });
      })
      .catch((error) => {
        for (const [key, value] of Object.entries(error.response.data.errors)) {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: "error",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text:value,
          },
        });
        }
      
      });

    store
      .dispatch("candidate/fetchSkills", {})
      .then((response) => {
        skillsData.value = response.data.data.items.map((x) => {
          return { text: x.skillSetName, value: x.id };
        });
      })
      .catch((error) => {
        for (const [key, value] of Object.entries(error.response.data.errors)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "error",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text:value,
          },
        });
        }
        if (error.response.status === 404) {
          skillsData.value = undefined;
        }
      });

    const onSubmit = (event) => {
      event.preventDefault();

      const blankUserData = {
        id: userData.value.id,
        fullName: userData.value.fullName,
        title: userData.value.title,
        email: userData.value.email===""?null:userData.value.email,
        mobileNumber: userData.value.mobileNumber,
        genderId: userData.value.genderId,
        candidateProfileTypeId: userData.value.candidateProfileTypeId,
        university: userData.value.university,
        currentCompany: userData.value.currentCompany,
        currentCity: userData.value.currentCity,
        birthDate: userData.value.birthDate===""?null:userData.value.birthDate,
        languages: userData.value.languages,
        website: userData.value.website,
        bio: userData.value.bio,
        statusId: userData.value.statusId,
        candidateContactOptions: userData.value.candidateContactOptions,
        candidateSkillLevels: userData.value.candidateSkillLevels,
        // candidateAddresses: [
        //   {
        //  id: userData.value.candidateAddresses[0]?.id | 0,
        addressLine1: userData.value.addressLine1,
        addressLine2: userData.value.addressLine2,
        postCode: userData.value.postCode,
        city: userData.value.city,
        state: userData.value.state,
        country: userData.value.country,
        // isPrimaryAddress: true,
        //   },
        // ],
      };

      store
        .dispatch("candidate/editUser", blankUserData)
        .then((response) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: "Update success",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          //router.push("/apps/list").catch(() => {});
         // router.go("/apps/list").catch(() => {});
        })
        .catch((error) => {
            for (const [key, value] of Object.entries(error.response.data.errors)) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: "error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text:value,
            },
          });
            }
        });
        //router.go();
    };

    return {
      userData,
      pdfData,
      skillsData,
      onSubmit,
    };
  },
};
</script>

<style>
</style>
